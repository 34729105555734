<template>
  <div class="main">
    <div class="main-box">
      <div class="box-item">
        <p>درگاه پرداخت :</p>
        <p>{{ gateway }}</p>
      </div>
      <div class="box-item">
        <p>مقدار :</p>
        <p>{{ $toLocal(amount) }} تومان</p>
      </div>
      <div class="box-item">
        <p>کد رهگیری :</p>
        <p>{{ code }}</p>
      </div>
      <div class="box-item">
        <p>وضعیت :</p>
        <span :class="{'red':!isDone , 'green' :isDone}"   >{{ status }}</span>
      </div>
      <button class="login-btn" @click="$router.push('/profile/wallet')">بازگشت به کیف پول ها</button>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      status: '',
      code: '',
      amount: '',
      gateway: ''
    }
  },
  async created() {
    const res = await this.$axios(`/wallets/deposit-status?id=${this.$route.query.id}`)

    this.status = res.baseDTO.isDone;
    this.amount = res.baseDTO.amount
    this.gateway = res.baseDTO.paymentGateway
    this.code = res.baseDTO.hashCode
  }
}
</script>

<style lang="scss" scoped>
.red{
  color: red;
}
.green{
  color: green;
}
.main {
  // background-image: url('../../../assets/Home/login.png');
  width: 100%;
  height: calc(100vh - 48px);
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  background-color: #031B27;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;

  .main-box {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    background: #0a1128;
    /*height: 200px;*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    margin: 0 auto;
    color: white;

    .box-item {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
    }
  }

  .login-btn {
    border: none;
    background: green;
    border-radius: 6px;
    height: 44px;
    font-size: 14px;
    color: #FCFCFC;
    width: 100%;
  }
}
</style>
